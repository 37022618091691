<template>
  <div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item">
        <md-field>
          <label :for=label>{{ label }}</label>
          <md-select v-model=selectedValue :name=label :id=label>
            <md-option
              v-for="(option, index) in options"
              :key="index"
              :value="option.value"
              v-on:click="setSelectedOption(option.value)">{{ $t(option.label) }}</md-option>
          </md-select>
        </md-field>
      </div>
    </div>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        isMenuVisible: false,
        options: []
      };
    },
    props: {
      selected: {
        type: [String, Number, Object]
      },
      exceptedValues: {
        type: Array
      },
      defaultValue: {
        type: [String, Number, Object]
      },
      enum: {
        type: Object,
        required: true
      },
      ressourceName: {
        type: String,
        required: true
      },
      optionsCaption: {
        type: String,
        required: false
      },
      validationRules: {
        type: String,
        required: false
      },
      validationName: {
        type: String,
        required: false
      },
      validateOnChange: {
        type: Boolean,
        required: false
      },
      label: {
        type: String,
        required: false
      }
    },
    created() {
      this.setOptions();
      if ((this.defaultValue || this.defaultValue === 0) && !this.selectedValue) {
        this.selectedValue = this.defaultValue;
      }
      if (this.validationRules && this.validator) {
        this.validator.attach({
          name: this.validationName ? this.validationName : this.ressourceName,
          rules: this.validationRules,
          getter: () => this.selected
        });
      }
    },
    methods: {
      setSelectedOption(option) {
        this.selectedValue = option;
        this.isMenuVisible = false;
      },
      toggleMenu() {
        this.isMenuVisible = !this.isMenuVisible;
      },
      isInclude(value) {
        return !this.exceptedValues || !this.exceptedValues.includes(value);
      },
      setOptions() {
        const self = this;
        this.options = [];
        Object.keys(this.enum).forEach((property) => {
          var optionValue = self.enum[property];
          if (self.isInclude(optionValue)) {
            self.options.push({ label: self.ressourceName + '.' + property, value: optionValue });
          }
        });
      }
    },
    computed: {
      selectedValue: {
        get() { return this.selected; },
        set(val) { this.$emit('update:selected', val); }
      },
      selectedOptionText() {
        var foundElement = this.options.find(elem => elem.value === this.selected);
        if (foundElement) {
          return this.$t(foundElement.label);
        }
        return this.optionsCaption ? this.optionsCaption : '';
      }
    },
    watch: {
      exceptedValues() {
        this.setOptions();
      },
      selected(newValue) {
        if (this.validateOnChange && this.validationRules && this.validator) {
          this.validator.validate(this.validationName ? this.validationName : this.ressourceName, newValue);
        }
      },
      validationRules(newValue) {
        this.validator.detach(this.validationName ? this.validationName : this.ressourceName);
        if (newValue && this.validator) {
          this.validator.attach({
            name: this.validationName ? this.validationName : this.ressourceName,
            rules: newValue,
            getter: () => this.selected
          });
        }
      }
    }
  };
</script>

<style lang="scss">
  .btn-group {
    outline: none;
    position: relative;
  }

    .btn-group .dropdown-toggle {
      background: transparent;
      border: none;
      width: 100%;
      border: 0.0625rem solid #EAEAEA;
      border-radius: 0.625rem;
      cursor: pointer;
      padding: 0 0.875rem;
      height: 3.125rem;
      line-height: 3.125rem;
      box-sizing: border-box;
      color: #000;
      position: relative;
    }

      .btn-group .dropdown-toggle:hover,
      .btn-group .dropdown-toggle.select {
        border-color: #0E848D;
      }

      .btn-group .dropdown-toggle:after {
        content: "\F107";
        font-family: "Font Awesome 5 Pro";
        display: inline-block;
        font-size: 1em;
        font-weight: normal;
        position: absolute;
        right: 1rem;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        pointer-events: none;
      }

    .btn-group .dropdown-menu.hide {
      display: none;
    }

    .btn-group .dropdown-menu {
      display: block;
      border: 0.0625rem solid #EAEAEA;
      padding: 0;
      margin: 0;
      width: 100%;
      position: absolute;
      left: 0;
      top: 50px;
      background-color: #fff;
      z-index: 10;
    }

      .btn-group .dropdown-menu li {
        list-style: none;
      }

        .btn-group .dropdown-menu li a {
          color: #000;
          display: block;
          padding: 0 0.875rem;
          text-decoration: none;
        }

          .btn-group .dropdown-menu li a:hover {
            background-color: #0E848D;
            color: #fff;
          }
</style>
